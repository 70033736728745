.title {
  text-align: center;
}

.content {
  width: 60%;
  margin: 0 auto;
  white-space: pre-line;
}

.date {
  font-size: 10px;
  color: #777;
  font-weight: 100;
}

.separator {
  margin: 30px 0 50px;
}

.container_image_note {
  text-align: center;
}

.image_note {
  margin: 0 auto;
  max-width: 600px;
  height: auto;
  object-fit: cover;
}

.links.return {
  font-size: 30px;
  display: block;
  margin-bottom: 50px;
}

.links.return::before {
  content: '<< ';
}

.checkbox_container {
  margin-left: 16px;
  width: initial;
}

.title_container {
  display: flex;
  justify-content: center;
}
