.form_note {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  color: red;
  font-size: 30px;
  font-weight: bold;
}

.button {
  margin-top: 20px;
  width: 98%;
}
