.loader {
  position: fixed;
  top: 0;
  background: rgb(191, 191, 191, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}
