.title {
  margin-left: 15px;
}

.user {
  font-size: 20px;
  margin-bottom: 7px;
}

.new_note {
  font-size: 15px;
  color: #666;
}
