.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}

.form {
  display: flex;
  flex-direction: column;
  width: 350px;
}

.title_input {
  color: #555;
  font-size: 15px;
}

.input {
  display: block;
  width: 97%;
  height: 27px;
  border-radius: 12px;
  border: 1px solid #000;
  padding: 5px 0 5px 10px;
  font-size: 20px;
  margin: 7px 0 10px;
}

.button {
  cursor: pointer;
  height: 40px;
  border-radius: 19px;
  font-size: 20px;
  margin-top: 5px;
  width: 100%;
  background-color: transparent;
}

.error {
  text-align: center;
  margin-top: 13px;
  font-size: 14px;
  color: red;
}

.textarea {
  width: 96%;
  padding: 15px;
  font-size: 20px;
  border-radius: 14px;
}

.checkbox_container {
  margin-right: 60px;
  display: flex;
  width: 100%;
  justify-content: end;
  font-size: 20px;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  width: 36px;
}
